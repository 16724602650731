.loader {
  height: 65vh;
}

.chat-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid #000;
  padding-bottom: 24px;
  margin-bottom: 24px;
  padding-right: 10px;
  height: 300px;
  overflow-y: auto;
}

.chat-container .message {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.chat-container .message.other {
  align-items: flex-start;
}

.chat-container .message.self {
  align-items: flex-end;
}

.chat-container .message-content {
  max-width: 50%;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  font-size: 0.875rem;
}

.chat-container .message.other .message-content {
  background-color: var(--unnamed-color-d9d9d9);
  border-radius: 10px 10px 10px 0;
}

.chat-container .message.self .message-content {
  background-color: var(--unnamed-color-222344);
  color: #fff;
  border-radius: 10px 10px 0;
}

.chat-container .date {
  font-size: 0.8em;
  color: #999;
}

.type-message textarea {
  background-color: var(--unnamed-color-f5f5f5);
  border: none;
  outline: none;
  height: 60px;
  border-radius: 10px;
  padding: 16px 20px;
  font-size: 0.875rem;
}

.type-message textarea::placeholder {
  color: #a4a4a4;
  font-size: 0.875rem;
}

.other-user {
  border-bottom: 1px solid #a4a4a475;
  padding-bottom: 8px;
}

.other-user .img-holder {
  width: 40px;
  height: 40px;
  border: 2px solid var(--unnamed-color-222344);
  border-radius: 50px;
}

.other-user .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: inherit;
}

.selected-user {
  background-color: #222344;
  border-bottom: 1px solid white;
  padding-bottom: 8px;
  color: white;
}

.selected-user .img-holder {
  width: 40px;
  height: 40px;
  border: 2px solid white;
  border-radius: 50px;
}

.selected-user .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: inherit;
}

.chat-users-sidebar {
  border-right: 1px solid #a4a4a475;
}

.chat-users-sidebar .user-search {
  font-size: 0.875rem;
  padding-left: 2.5rem;
}

.chat-users-sidebar .search-icon {
  left: 10px;
  font-size: 0.875rem;
}

.chat-users-sidebar .username {
  font-size: 0.875rem;
  font-weight: 500;
}

.chat-users-sidebar .user-msg,
.chat-users-sidebar .time {
  color: #a4a4a4;
  font-size: 0.8em;
}

.chat-background {
  height: 500px !important;
}

.options-bg-dark:hover {
  background: #ececec;
}

.blocked-info {
  background-color: #222344;
}

@media (max-width: 767px) {
  .chat-users-sidebar {
    border-right: none;
  }
  .users-list {
    height: 165px;
    overflow: auto;
  }

  .chat-background {
    height: 700px !important;
  }

  .chat-container {
    height: 350px;
  }
}

@media (max-width: 575px) {
  .chat-container {
    gap: 4px;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }
  .chat-container .message-content {
    max-width: 80%;
  }
  .chat-background {
    height: 500px !important;
  }
  .chat-container {
    height: 200px;
  }
}
