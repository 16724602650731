.accordion-header .accordion-button {
  background-color: #222344;
  color: white;
}

button:focus:not(:focus-visible) {
  box-shadow: none;
}

.accordion-button::after {
  content: ""; /* Ensures the pseudo-element is displayed */
  background-image: url("Assets/down-arrow.png"); /* Default down arrow */
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 16px; /* Adjust according to your icon size */
  height: 16px;
  transform: rotate(0deg); /* Default state */
  transition: transform 0.3s ease-in-out, background-image 0.1s ease-in-out; /* Smooth transition */
}

.accordion-button:not(.collapsed)::after {
  background-image: url("Assets/down-arrow.png"); /* Change to upload icon */
  /* transform: rotate(0deg); */
}
