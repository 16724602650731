.form-modal .modal-content{
        border-radius: 17px!important;
}
.form-modal .select-file{
        border: 2px dashed #A4A4A4;
}
.form-modal .select-file .delete-icon{  
        height: 17px;
        position: absolute;
        right: 8px;
        top: 8px;
}    
.form-modal .select-file .uplaod-file{
        height: 30px;
}   

.form-modal .select-file .btn-select-file{
        background-color: var(--unnamed-color-d9d9d9);
        font-size: 10px;
        border-radius: 20px;
        font-weight: 700;
        color: black;
}
.form-modal .select-file .user-selected-file{
        height: 90px;

}

.user-img-in-message-modal, .featured-plan .image-holder  .user-profile-img{
        height: 100%;
        width: 100%;
        object-fit: cover;
}
.send-message-modal .image-holder, .featured-plan .image-holder {
        width: 3.438rem;
        height: 3.438rem;
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid var(--unnamed-color-222344);
}
.featured-plan .image-holder {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid var(--unnamed-color-222344);
}
.form-modal .payment-icon{
        width:5.2rem;

}

@media (min-width:576px){
        .form-modal  .text-container{
                margin-left: 3rem;
        }
}
