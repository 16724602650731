.form-header {
  background-color: var(--form-background-color);
}
.PolygonRuler {
  display: flex;
  height: 0;
  padding-bottom: 3.90625%;
  position: relative;
  width: 100%;
}
.PolygonRuler .PolygonRuler__SVG {
  bottom: 0rem;
  height: 35px;
  right: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
}
.PolygonRuler .PolygonRuler__SVG .PolygonRuler__Polygon {
  fill: #ffff;
}
.forms .title {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 100;
  right: 0;
}
.forms .form-control,
.forms .form-select {
  padding: 0.575rem 0.75rem;
}
.forms .small-heading {
  color: var(--form-background-color);
  font-weight: 600;
  font-size: 18px;
}
.forms .register-btn {
  width: 270px;
}
.otp-form .otp-input {
  width: 70px;
  border: none;
  border-bottom: 4px solid #dbdbdb;
  text-align: center;
}
.otp-form .otp-input:focus {
  outline: none;
}
.register-form .google-login,
.register-form .apple-login,
.sigin-form .google-login,
.sigin-form .apple-login {
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 1px 6px #3e3e3e29;
  width: 35px;
  height: 35px;
}
.register-form .apple-login img,
.register-form .google-login img,
.sigin-form .google-login img,
.sigin-form .apple-login img {
  height: 24px;
}
.password-input-container .eye-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.error {
  font-size: 0.7rem;
}

@media (max-width: 500px) {
  .otp-form .otp-input {
    width: 40px;
    border: none;
    border-bottom: 4px solid #dbdbdb;
    text-align: center;
  }
}
