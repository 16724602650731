.profile-about {
  overflow: hidden;
  background-color: var(--unnamed-color-ffffff);
}
.profile-about .about-header {
  height: 9.375rem;
  background-color: var(--unnamed-color-d9d9d9);
}
.profile-about .about-logo-container {
  margin-top: -3.75rem;
}
.profile-about .about-logo-container .edit-logo {
  height: 2.5rem;
}
.profile-about .tick-logo {
  color: white;
  background-color: var(--form-background-color);
  height: 1.688rem;
  width: 1.688rem;
  border-radius: 50%;
}
.profile-about .feature-profile-btn {
  background-color: white;
  width: 290px;
  box-shadow: 0px 3px 6px #00000029;
}
.profile-about .feature-icon {
  width: 3.125rem;
}
.profile-tag {
  background-color: var(--form-background-color);
  display: inline-block;
  color: white;
  font-size: 1.125rem;
  width: 11.25rem;
  font-weight: 500;
  box-sizing: content-box;
}

.basic-detail .basic-detail-icon {
  height: 15px;
}

.profile .education-icon {
  height: 3.75rem;
  width: 3.75rem;
  background-color: var(--unnamed-color-e6e5e5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile .education-icon img {
  width: 80%;
}

.profile .approval .approved {
  color: var(--unnamed-color-0b721c);
  background-color: var(--unnamed-color-e6e5e5);
  border-radius: 15px;
  font-size: 11px;
  font-weight: 600;
}
.profile .approval .search-eye-icon {
  height: 1rem;
}
.profile .approval .edit-icon {
  height: 1.25rem;
  cursor: pointer;
}
.profile .add-icon {
  background-color: var(--unnamed-color-e6e5e5);
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
}
.profile .job-proof {
  color: var(--unnamed-color-0b721c);
}
.profile .experience-btn-more {
  display: flex;
  width: 6.875rem;
  border: 2px solid #b1b1b1;
  border-radius: 20px;
}
.profile .experience-btn-more .add-icon {
  background-color: var(--unnamed-color-e6e5e5);
  border-radius: 50%;
  height: 1.313rem;
  width: 1.313rem;
  cursor: pointer;
}

.profile .user-info {
  border: 0.5px solid #b1b1b1;
}

/* Hobbies Accordion */
.hobby-accordion {
  display: inline-block;
}
.hobby-accordion-body {
  overflow: hidden;
  transition: 0.4s ease-in-out;
}

.hobby-accordion .angle-down {
  color: #7a7a7a;
  transition: 0.4s ease-in-out;
}

.profile .percentage {
  height: 10px;
  width: 300px;
  border: 2px solid var(--form-background-color);
  border-radius: 20px;
}

.profile .percentage-bar {
  position: absolute;

  height: 100%;
  background-color: var(--form-background-color);
}
@media (max-width: 575px) {
  .profile-about .feature-profile-btn {
    width: 100%;
  }
}
