html {
  font-size: 16px; /* Standard font size */
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  --unnamed-color-f5f5f5: #f5f5f5;
  --unnamed-color-121331: #121331;
  --unnamed-color-beaeef: #beaeef;
  --unnamed-color-9b88d7: #9b88d7;
  --unnamed-color-a4a4a4: #a4a4a4;
  --unnamed-color-373639: #373639;
  --unnamed-color-222344: #222344;
  --unnamed-color-d9d9d9: #d9d9d9;
  --unnamed-color-e6e5e5: #e6e5e5;
  --unnamed-color-0b721c: #0b721c;
  --unnamed-color-be0d0d: #be0d0d;
  --unnamed-color-707070: #707070;
  --unnamed-color-005fac: #005fac;
  --unnamed-color-ffffff: #ffffff;
  --unnamed-color-7a7a7a: #7a7a7a;
  --unnamed-color-00000000: #00000000;
  --form-background-color: #1d2c42;
}

body {
  background-color: var(--unnamed-color-f5f5f5);
  position: relative;
  min-height: 100vh;
  padding-bottom: 7.5rem;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #222344;
}

.transparent-btn-theme,
.clr-btn-theme:hover,
.clr-btn-theme:focus,
.clr-btn-theme:active {
  border: 1px solid var(--unnamed-color-222344);
  border-radius: 3px;
  font-weight: 500;
}

.transparent-btn-theme:hover,
.transparent-btn-theme:focus,
.transparent-btn-theme:active {
  background-color: var(--unnamed-color-222344);
  color: #fff;
}

.clr-btn-theme {
  border: 1px solid var(--unnamed-color-222344);
  border-radius: 3px;
  background-color: var(--unnamed-color-222344);
  color: #fff;
}

.theme-link {
  color: var(--unnamed-color-222344);
  text-decoration: none;
  font-weight: 500;
}

.theme-link:hover,
.theme-link:focus,
.theme-link:active {
  text-decoration: underline;
}

.background-container {
  background: #fff;
  border: 0.5px solid #adb5bd55;
  border-radius: 10px;
}

.grey-background {
  background-color: var(--unnamed-color-d9d9d9);
  border-radius: 10px 10px 0 0;
}

.select-wrapper {
  color: rgb(112, 112, 112);
  width: 100%;
  height: 2.8rem;
  border-color: rgb(230, 229, 229);
  font-size: 12px;
  border-radius: 5px;
}

.select-wrapper::after {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
  content: "\f107";
  font-size: 12px;
  position: absolute;
  color: rgb(112, 112, 112);
  right: 10px;
}

.select-wrapper .question-dropdown {
  color: #707070;
  width: 100%;
  height: 2.8rem;
  border-color: #e6e5e5;
  font-size: 12px;
  padding-left: 8px;
  border-radius: 5px;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
}

/* Some common Classes */
.row {
  --bs-gutter-x: 1rem;
}
.form-control,
.form-select {
  border: none;
  box-shadow: 0px 1px 6px #7871717a !important;
}
.label {
  font-weight: 500 !important;
  color: #000000;
}
.text-small {
  font-size: 12px;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}
.pointer {
  cursor: pointer;
}
.text-black {
  color: #000000;
}
.text-light {
  color: var(--unnamed-color-707070) !important;
}
.text-smallest {
  font-size: 10px;
}
.border-none {
  border: none !important;
}
/* Some common Classes */

/*same passions*/
.same-passions .user-name {
  font-weight: 600;
}

.same-passions .profession {
  font-size: 12px;
}

.same-passions .transparent-btn-theme {
  font-size: 12px;
}

.same-passions .image-holder {
  width: 45px;
  height: 45px;
  border: 2px solid var(--unnamed-color-222344);
  border-radius: 50px;
}

.same-passions .user-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: inherit;
}

.same-passion-details .img-holder {
  background-color: var(--unnamed-color-d9d9d9);
  bottom: -0.813rem;
  right: 0.813rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.same-passion-details .img-holder .verified-symbol {
  width: 0.938rem;
}
/*same passions*/

/*my info*/
.my-info .image-holder {
  width: 3.438rem;
  height: 3.438rem;
  border: 2px solid var(--unnamed-color-222344);
  border-radius: 50px;
  position: relative;
  bottom: -1.25rem;
}

.my-info .user-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: inherit;
}

.my-info p {
  font-size: 0.875rem;
}
/*my info*/

/*featured profiles*/
.featured-profiles .image-holder {
  width: 6.875rem;
  height: 6.875rem;
  border: 3px solid var(--unnamed-color-222344);
  border-radius: 50%;
  position: relative;
  bottom: -2.813rem;
}

.featured-profiles .featured-img {
  width: 3.125rem;
}

.grey-background .featured-image {
  height: 18px;
  left: 0.5rem !important;
  bottom: 0.2rem !important;
}

.featured-profiles .image-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: inherit;
}

.featured-profiles .heart-icon {
  width: 1.563rem;
  right: 1.563rem;
  top: 1.563rem;
  cursor: pointer;
}

.featured-profiles {
  margin-bottom: 1.5rem;
}

.featured-profiles:nth-last-child(1) {
  margin-bottom: 0;
}
/*featured profiles*/

/*ads*/
.ads {
  border: 1px solid #b1b1b1;
  border-radius: 6px 6px;
}

.ads-image {
  width: 100%;
  height: 320px;
  object-fit: contain;
}

.ads .heading {
  font-weight: 600;
}

.ads .date {
  color: var(--unnamed-color-707070);
  font-size: 1.125rem;
}
/*ads*/

/* User image logo  */
.user-logo {
  height: 7.5rem;
  width: 7.5rem;
  border: 2px solid var(--form-background-color);
  border-radius: 50%;
  position: relative;
}
.user-logo .user-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.user-logo .camera-icon {
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 2px solid var(--form-background-color);
  position: absolute;
  right: 3px;
  bottom: -2px;
  cursor: pointer;
}
.user-logo .camera-icon .icon-in {
  height: 0.875rem;
}
/* User image logo  */

/*same passion*/
.same-passion-section .same-passion-details {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  text-align: center;
}

.same-passion-section .grey-background {
  height: 2.813rem;
}

.same-passion-section .heart-icon {
  width: 1.25rem;
  right: 0.625rem;
  bottom: 0.625rem;
  cursor: pointer;
}

.same-passion-section .image-holder {
  width: 5.313rem;
  height: 5.313rem;
  border-radius: 50%;
  border: 3px solid var(--unnamed-color-222344);
}

.same-passion-section .image-holder img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
  object-position: top;
}

.same-passion-section .percentage {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--unnamed-color-222344);
  color: #fff;
  border-radius: 10px 0 10px 0;
  padding: 2px 10px;
  font-size: 10px;
}

.same-passion-section .transparent-btn-theme {
  font-size: 12px;
}
/*same passion*/

/*feeds*/
.user-feed .img-holder {
  width: 4.063rem;
  height: 4.063rem;
  border: 2px solid var(--unnamed-color-222344);
  border-radius: 50px;
}

.user-feed .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: inherit;
}

.user-feed .profession {
  color: #707070;
  font-size: 0.938rem;
}

.feed-post-input .img-holder {
  width: 3.125rem;
  height: 3.125rem;
  border: 2px solid var(--unnamed-color-222344);
  border-radius: 50px;
}

.feed-post-input .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: inherit;
}

.feed-post-input input {
  border: 1px solid #e6e5e5;
  border-radius: 39px;
  padding: 0.625rem 1rem;
  outline: none;
}

.feed-post-input input::placeholder {
  color: #a4a4a4;
  font-weight: 300;
}
/*feeds*/

/*other-pages*/
.other-pages {
  border: 0.5px solid #b1b1b1;
  border-radius: 4px;
}

.other-pages .heading {
  color: #222344;
  font-weight: 600;
}

.other-pages .link {
  display: block;
  color: #000000;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 8px;
}

.other-pages .link:last-child {
  margin-bottom: 0;
}
/*other-pages*/

.current {
  margin: 0 !important;
  margin-bottom: 3px !important;
  font-size: 8px;
  background: #1d2c42;
}

.page {
  height: 65vh;
}

.feed-btn {
  padding: 6px 15px;
  width: 170px;
}

.notification-section {
  height: 500px;
  width: 400px !important;
}

.notification-text {
  font-size: 0.8rem;
}

.notificationBackground {
  background-color: #dfe7f5 !important;
}

.notification-title {
  background-color: #d9d9d9 !important;
}

.notification-section {
  scrollbar-width: thin;
  scrollbar-color: #1d2c42 white;
}

.notification-section::-webkit-scrollbar {
  width: 6px; /* Thin scrollbar width */
}

.notification-section::-webkit-scrollbar-track {
  background: #1d2c42; /* Track color */
  border-radius: 5px; /* Rounded track */
}

.notification-section::-webkit-scrollbar-thumb {
  background-color: #555; /* Scrollbar thumb color */
  border-radius: 5px; /* Rounded scrollbar thumb */
}

.notification-section::-webkit-scrollbar-thumb:hover {
  background-color: #777; /* Scrollbar thumb color on hover */
}

.pac-container {
  z-index: 99999 !important;
}

.image-holder {
  z-index: 100;
}

.checkbox-check:active {
  box-shadow: none;
}
.checkbox-check:hover {
  box-shadow: none;
}

.form-check-input {
  border-color: var(--unnamed-color-222344);
}

.form-check-input:checked {
  background-color: var(--unnamed-color-222344);
  border-color: var(--unnamed-color-222344);
}

.form-check-input:focus {
  box-shadow: none !important;
  outline: none !important;
  border-color: var(--unnamed-color-222344);
}

.store-image-size {
  height: 40px;
}

.image-preview-div {
  bottom: 8rem;
}

.image-preview-div .cross-btn {
  font-size: 1.2rem;
  right: 0.7rem;
  top: 0.7rem;
}

.address-line {
  font-size: 0.7rem;
  color: rgb(168, 168, 168);
}

.pac-item {
  padding: 10px 5px;
  text-wrap: wrap;
  line-height: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.pac-icon {
  margin-top: 0px;
}

.pointer {
  cursor: pointer;
}

.confirm-modal .modal-content {
  border-radius: 1.3rem;
  border: none;
}
.confirm-modal .cross-btn {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.warning-image {
  width: 5rem;
}
.modal-buttons {
  padding-bottom: 2.5rem;
}
.cancel-btn {
  color: var(--unnamed-color-7a7a7a);
  border: 1px solid var(--unnamed-color-a4a4a4);
  padding: 5px 10px;
}
.cancel-btn:hover {
  color: var(--unnamed-color-ffffff);
  background-color: var(--unnamed-color-a4a4a4);
}

@media (max-width: 575px) {
  html {
    font-size: 12px;
  }
}
@media (max-width: 575px) {
  .notification-section {
    width: 250px !important;
    height: 400px !important;
  }
}
