.navbar .nav-link {
  color: #9f9d9d;
}

.navbar .nav-link.active,
.navbar .nav-link:hover,
.navbar .nav-link:focus,
.navbar .nav-link:active {
  color: var(--unnamed-color-222344);
  font-weight: 600;
}

.navbar .navbar-nav .nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
}

.navbar .auth-btn {
  padding: 6px 30px;
}
.navbar .user-img-header {
  height: 35px;
  width: 35px;
  object-fit: cover;
  border-radius: 50%;
}
.navbar .header-dropdown-container {
  position: absolute;
  z-index: 100000;
  right: 45px;
  top: 45px;
}

.navbar .header-dropdown {
  width: 10.313rem;
  background-color: white;
  box-shadow: 0px 3px 16px #00000029;
  z-index: 1000000;
}

.navbar .drop-down-links {
  display: block;
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.1s ease-in;
  font-size: 0.875rem;
}

.navbar .drop-down-links:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.navbar .notificaton-count {
  position: absolute;
  background-color: #be0d0d;
  height: 15px;
  min-width: 15px;
  font-size: 8px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: -5px;
  right: -3px;
}

.notification-border {
  border-color: #b9b8b8 !important;
}

.notifications:last-child {
  border-bottom: 0 !important;
}

.notifications:nth-last-child(1) {
  border-bottom: 0 !important;
}

.notification-heading {
  background-color: #1d2c42;
}

.notification-time {
  font-size: 0.6rem;
}

.notification-options {
  width: 200px;
  height: auto;
  z-index: 10 !important ;
  top: -20px;
  right: 10px;
}

.header-logo {
  width: 60px;
}

@media (max-width: 1199px) {
  .navbar .header-dropdown-container {
    right: 22px;
  }
}

@media (max-width: 991px) {
  .navbar-collapse .bell-prof-icon {
    display: none !important;
  }
}

@media (max-width: 575px) {
  .h-username,
  .h-prof {
    display: none;
  }
  .navbar .navbar-nav .nav-link {
    padding: 8px 0;
    border-bottom: 1px solid #a4a4a475;
  }
  .navbar .navbar-nav .nav-item:last-child .nav-link {
    border-bottom: none;
    padding-bottom: 0;
  }
  .navbar .header-dropdown-container {
    right: 8px;
    top: 40px;
  }
  .header-logo {
    width: 40px;
  }
}
